import Vue from "vue";

Vue.mixin({
  methods: {
    focusInput() {
      setTimeout(() => {
        document.getElementById("newsletter-input").focus();
      }, 500);
    }
  }
});
