import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _ab15d282 = () => interopDefault(import('../pages/legal.vue' /* webpackChunkName: "pages/legal" */))
const _5f70148e = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _0547af50 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: '',
  linkExactActiveClass: 'is-active',
  scrollBehavior,

  routes: [{
      path: "/legal",
      component: _ab15d282,
      name: "legal"
    }, {
      path: "/privacy",
      component: _5f70148e,
      name: "privacy"
    }, {
      path: "/",
      component: _0547af50,
      name: "index"
    }],

  fallback: false
}

export function createRouter() {
  return new Router(routerOptions)
}
