import Vue from "vue";
import VueToasted from "vue-toasted";
import { i18n } from "./vue-i18n";

Vue.use(VueToasted, {
  iconPack: "fontawesome"
});

const errorOptions = {
  type: "error",
  theme: "bubble",
  position: "bottom-center",
  duration: 3000
};

const successOptions = {
  type: "success",
  theme: "bubble",
  position: "bottom-center",
  duration: 3000
};

//register the error toast with the custom message
Vue.toasted.register(
  "my_error",
  payload => {
    // if there is no message passed show default message
    if (!payload.message) {
      return i18n.t("toasts.error");
    }
    // if there is a message show it with the message
    return payload.message;
  },
  errorOptions
);

//register the success toast with the custom message
Vue.toasted.register(
  "my_success",
  payload => {
    // if there is no message passed show default message
    if (!payload.message) {
      // eslint-disable-next-line no-undef
      return i18n.t("toasts.success");
    }
    // if there is a message show it with the message
    return payload.message;
  },
  successOptions
);

//injects vue toasted into store
export default function(ctx, inject) {
  inject("toasted", Vue.toasted);
}
