import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

//export as const for use in toasted.js plugin
export const i18n = new VueI18n({
  locale: navigator.language.split("-")[0],
  fallbackLocale: "en",
  messages: {
    en: require("@/lang/en-US.json"),
    de: require("@/lang/de-AT.json")
  }
});

export default ({ app }) => {
  // Set i18n instance on app
  // This way we can use it in middleware and pages asyncData/fetch
  app.i18n = i18n;
};
