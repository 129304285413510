window.addEventListener("load", function() {
  window.cookieconsent.initialise({
    palette: {
      popup: {
        background: "#803cc3"
      },
      button: {
        background: "#f7b731",
        text: "#ffffff"
      }
    },
    theme: "classic",
    content: {
      message:
        "This website uses cookies for statistical analyis to ensure you get the best experience on our website. By using this site you accept the usage of these cookies.",
      dismiss: "Accept",
      href: "/privacy"
    }
  });
});
